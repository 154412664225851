import { reactive, readonly } from "vue";

const state = reactive({
    isOpened: true,
});

function toggle() {
    state.isOpened = !state.isOpened;
}

function open() {
    state.isOpened = true;
}

function close() {
    state.isOpened = false;
}

export default { state: readonly(state), toggle, open, close };
