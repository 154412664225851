<template>
    <transition name="fade" mode="in-out">
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: "Fade"
    }
</script>

<style scoped lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity 300ms;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
</style>
