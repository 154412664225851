/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [
        './src/app/components/**/*.vue',
        './src/main.js',
        './src/assets/styles/**/*.scss',
        './craft/templates/**/*.twig',
    ],
    safelist: [
        'lg:order-first',
        'lg:order-last',
        'bg-grey-10',
        {
            pattern: /dark-overlay--(\d0|100)/,
        },

        // Used in the spacer CMS block
        '-mt-4',
        '-mt-6',
        '-mt-10',
        '-mt-16',
        '-mt-20',
        'pt-4',
        'pt-6',
        'pt-10',
        'pt-16',
        'pt-20',
        'lg:-mt-4',
        'lg:-mt-6',
        'lg:-mt-10',
        'lg:-mt-16',
        'lg:-mt-20',
        'lg:pt-4',
        'lg:pt-6',
        'lg:pt-10',
        'lg:pt-16',
        'lg:pt-20',
    ],
    darkMode: 'class',
    theme: {
        container: {
            center: true,
        },

        screens: {
            md: "768px",
            lg: "1024px",
            xl: "1200px",
            xxl: "1440px",
        },

        fontFamily: {
            epilogue: ['Epilogue', 'sans-serif'],
        },

        colors: {
            beige: '#b4814f',

            success: {
                dark: '#186b15',
                DEFAULT: '#249f1f',
                light: '#98ea94',
            },
            alert: {
                dark: '#6d1212',
                DEFAULT: '#de3838',
                light: '#ed9292',
            },
            info: '#4569d0',

            grey: {
                98: '#f9f9f9',
                95: '#f3f3f3',
                90: '#e5e5e6',
                80: '#cbcbcd',
                70: '#b2b2b3',
                60: '#98989a',
                40: '#646466',
                20: '#333333',
                10: '#1a1a1a',
            },

            black: '#000',
            white: '#fff',
            transparent: 'transparent',
            current: 'currentColor',
        },

        boxShadow: {
            m: '0 12px 12px 0 rgba(0, 0, 0, 0.06), 0 0 10px 1px rgba(0, 0, 0, 0.06)',
            l: '0 24px 24px 0 rgba(0, 0, 0, 0.12)',
        },

        extend: {
            spacing: {
                '7.5': '1.875rem',
                18: '4.5rem',
                22: '5.5rem',
                30: '7.5rem',
                50: '12.5rem',
                '7/10': () => {
                    return 7/10*100 + '%';
                }
            },
        },
    },
    plugins: [
        require("@tailwindcss/forms")({
            strategy: 'class',
        }),
    ],
}
