<template>
<div class="text-white relative" ref="root">
    <div class="absolute inset-0 w-full h-full">
        <slot name="background" />
    </div>
    <div class="container grid-container">
        <div class="grid-row">
            <div class="grid-col relative w-full min-h-[43.75rem] flex flex-col justify-center items-center text-center">
                <play-button @play="playVideo"
                             :consent-text="cookieConsentText"
                             :aria-label="playAriaLabel"
                             :show-text-on-hover="!isTarteaucitronServiceEnabled"
                             class="mb-4 md:mb-8"
                />

                <div>
                    <slot name="text" />
                </div>
            </div>
        </div>
    </div>
    <div class="absolute inset-0 w-full h-full" v-show="isPlaying">
        <slot name="video" />
    </div>
</div>
</template>

<script setup>
import PlayButton from '@/app/components/PlayButton';
import {onMounted, ref} from 'vue';

const props = defineProps({
    provider: String,
    cookieConsentText: String,
    playAriaLabel: String,
})


const isPlaying = ref(false);
const root = ref(null);
function playVideo() {
    const tarteaucitronEnableButton = root.value.querySelector('.tarteaucitronAllow');
    if (tarteaucitronEnableButton) {
        window.tarteaucitron.userInterface.respond(tarteaucitronEnableButton, true);
        isTarteaucitronServiceEnabled.value = true;
    }
    isPlaying.value = true;
}

const isTarteaucitronServiceEnabled = ref(false);
onMounted(() => {
    setTimeout(() => {
        isTarteaucitronServiceEnabled.value = !!window.tarteaucitron.state[props.provider];
    }, 1000);
});
</script>

<style scoped lang="scss">
:deep(iframe) {
    @apply w-full h-full;
}
</style>
