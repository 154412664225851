<template>
    <div>
        <slot name="navigation" v-bind="{
            slidesCount: carrousel ? carrousel.data.slidesCount.value : 0,
            currentSlide: carrousel ? carrousel.data.currentSlide.value : 0,
            prev: carrousel ? carrousel.prev : () => {},
            next: carrousel ? carrousel.next : () => {},
        }"
        />

        <carousel :settings="settings" ref="carrousel" aria-label="">
            <slot name="default" />
        </carousel>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { Carousel } from 'vue3-carousel';

const carrousel = ref(null);

const settings = {
    itemsToShow: 1.1,
    autoplay: 5000,
    transition: 400,
    wrapAround: true,
    breakpoints: {
        768: {
            itemsToShow: 3.1,
        },
    },
};
</script>

<style scoped lang="scss">
:deep(.carousel__viewport) {
    overflow: visible;
}

:deep(.carousel__slide) {
    justify-content: start;
    align-items: start;
    text-align: start;
    margin-left: -1px;
}
</style>
