<template>
<ol ref="root"
    class="scrolling-text whitespace-nowrap overflow-hidden">
    <li v-for="(word,index) in words"
        :key="index"
        v-text="word"
        class="h1 inline-block mx-8 lg:text-[4.2rem]"
    />
</ol>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import gsap from '@lahautesociete/gsap';
import { ScrollTrigger } from '@lahautesociete/gsap/ScrollTrigger';
import { ScrollToPlugin } from '@lahautesociete/gsap/ScrollToPlugin';

defineProps({ words: Array });

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const root = ref(null);

onMounted(() => {
    // setTimeout is there to "wait" for Tailwind CSS to be loaded, otherwise the computed values might not be accurate.
    setTimeout(() => {
        gsap.to(root.value, {
            scrollTrigger: {
                start: () => {
                    const triggerStart = (window.innerHeight - root.value.clientHeight) + 'px';
                    return `50px ${triggerStart}`;
                },
                end: '-50px top',
                scrub: 2,
                trigger: root.value,
                toggleActions: 'play pause resume reset',
            },
            scrollTo: {x: "max"},
        });
    }, 1000);
});
</script>
