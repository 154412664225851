<template>
    <div class="container grid-container">
        <div class="grid-row">
            <article class="grid-col w-full md:w-1/3 mb-12 md:mb-6"
                     v-for="(feature, index) in features"
                     :key="index"
                     @mouseover="onMouseOver(index)"
                     @mouseout="onMouseOut"
                     @click="goToSlide(index)"
            >
                <img :src="feature.image.mobile.url"
                     :width="feature.image.mobile.width"
                     :height="feature.image.mobile.height"
                     class="md:hidden mb-6"
                />

                <div class="flex flex-col lead mb-2 md:mb-6">
                    <span class="md:mb-4" v-text="feature.heading"></span>
                    <progress class="hidden md:block w-full"
                              :value="currentSlideIndex === index ? currentSlideProgress : 0"
                              :max="slideDuration"
                    />
                </div>
                <div class="wysiwyg-content body-2" v-html="feature.text"/>
            </article>
        </div>

        <div class="relative grid-row grid-row--images overflow-hidden md:mb-8"
             ref="imageContainer"
        >
            <div v-for="(feature, index) in features"
                 :key="index"
                 class="grid-col w-2/3 flex-grow-0 flex-shrink-0"
            >
                <img :src="feature.image.desktop.url"
                     :width="feature.image.desktop.width"
                     :height="feature.image.desktop.height"
                     :alt="feature.image.desktop.alt"
                     ref="images"
                />
            </div>
        </div>
        <div class="grid-row w-full justify-center" v-if="cta">
            <a :href="cta.url"
               class="btn btn--dark-primary dark:btn--light-primary"
               :target="cta.target"
               v-text="cta.label"
            />
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, onUnmounted, ref} from 'vue';

const props = defineProps({
    features: { type: Array, required: true },
    cta: { type: Object, default: null },
    duration: { type: Number, default: 5 },
});

const slideDuration = props.duration * 1000;
const currentSlideIndex = ref(0);
const hoveredSlide = ref(undefined);
const currentSlideProgress = ref(slideDuration);
const imageContainer = ref(null);
const images = ref([]);
let requestAnimationFrameId;
let previousTimestamp;

onMounted(() => {
    requestAnimationFrameId = requestAnimationFrame(animationStep);
});

onUnmounted(() => {
    pauseSlider();
});


function animationStep(timestamp) {
    if (currentSlideIndex.value === hoveredSlide.value) {
        pauseSlider();
        return;
    }

    if (previousTimestamp === undefined) {
        previousTimestamp = timestamp;
    }

    let elapsed = Math.floor(timestamp - previousTimestamp);
    currentSlideProgress.value = currentSlideProgress.value - elapsed;

    if (currentSlideProgress.value <= 0) {
        goToSlide(++currentSlideIndex.value % props.features.length);
    }

    previousTimestamp = timestamp;
    requestAnimationFrameId = window.requestAnimationFrame(animationStep);
}

/**
 * Event handler for @mouseout events on slides
 */
function onMouseOut() {
    hoveredSlide.value = undefined;
    resumeSlider();
}

/**
 * Event handler for @mouseover events on slides
 * @param index
 */
function onMouseOver(index) {
    hoveredSlide.value = index
}

/**
 * Pause the slider.
 */
function pauseSlider() {
    if (requestAnimationFrameId) {
        window.cancelAnimationFrame(requestAnimationFrameId);
    }

    window.cancelAnimationFrame(requestAnimationFrameId);
    requestAnimationFrameId = undefined;
    previousTimestamp = undefined;
}

/**
 * Resume the slider
 */
function resumeSlider() {
    if (requestAnimationFrameId) {
        return;
    }

    requestAnimationFrameId = window.requestAnimationFrame(animationStep);
}

const currentSlideImage = computed(() => images.value[currentSlideIndex.value]);

/**
 * Go to the slide at the given index
 * @param {Number} index
 */
function goToSlide(index) {
    currentSlideProgress.value = slideDuration;
    currentSlideIndex.value = index;
    const container = imageContainer.value;
    const image = currentSlideImage.value;
    const containerMargin = parseInt(getComputedStyle(container).marginLeft.replace(/px/, ''), 10);
    container.scrollTo({
        top: 0,
        left: image.getBoundingClientRect().x - container.getBoundingClientRect().x + container.scrollLeft + containerMargin,
        behavior: 'smooth',
    });
}
</script>

<style scoped lang="scss">
progress {
    border: none;
    background: #d0d0d0;
    height: 1px;

    &::-moz-progress-bar {
        background: black;
    }

    &::-webkit-progress-bar {
        background: transparent;
    }

    &::-webkit-progress-value {
        background: black;
    }
}

.grid-row--images {
    @apply hidden md:flex;

    flex-wrap: nowrap;
}
</style>
