<template>
    <main class="background-color-transition" ref="root" :class="classes">
        <slot />
    </main>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';

const root = ref(null);
const currentComponentBgClass = ref(['bg-white']);

function onIntersection(entries) {
    if (!entries[0].isIntersecting) {
        return;
    }

    currentComponentBgClass.value = Array.from(entries[0].target.classList).filter(className => {
        return className.match(/(.+:)?bg-(.+)/) !== null;
    });
}

const isBgColorDark = computed(() => {
    if (currentComponentBgClass.value.length > 1) {
        console.warn('The BackgroundColorTransition Vue component doesn\'t support elements with multiple Tailwind bg-* classes. Only the first one will be considered.');
    }

    return ['bg-black', 'bg-grey-10', 'bg-grey-20'].includes(currentComponentBgClass.value[0]);
});

const classes = computed(() => {
    const classes = Array.from(currentComponentBgClass.value);

    if (isBgColorDark.value) {
        classes.push('dark');
    }

    return classes.join(' ');
});

const observer = new IntersectionObserver(onIntersection, { threshold: 0.25 });
onMounted(() => {
    Array.from(root.value.children).forEach(element => {
        element.style.backgroundColor = 'transparent';
        observer.observe(element);
    });
});
</script>

<style lang="scss" scoped>
.background-color-transition,
:deep([class^='dark:']),
:deep([class*=' dark:']),
{
    @apply transition-colors duration-1000 ease-out;
}
</style>
