<template>
    <button @click="$emit('play')"
            @mouseenter="isHovered = true"
            @mouseleave="isHovered = false"
            class="flex items-center"
            :aria-label="ariaLabel"
            :title="ariaLabel"
    >
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 80 80"
             width="80"
             height="80"
             class="mx-4"
        >
            <path fill="#FFF" fill-rule="evenodd" stroke="#FFF" stroke-width="2" d="M40 1c10.77 0 20.52 4.365 27.577 11.423C74.635 19.48 79 29.23 79 40c0 10.77-4.365 20.52-11.423 27.577C60.52 74.635 50.77 79 40 79c-10.77 0-20.52-4.365-27.577-11.423C5.365 60.52 1 50.77 1 40c0-10.77 4.365-20.52 11.423-27.577C19.48 5.365 29.23 1 40 1zm-4.1 30.368v16.971l14.709-8.485-14.708-8.486z"/>
        </svg>

        <transition :css="false" @enter="onEnter" @leave="onLeave" :duration="1">
            <div v-show="isHovered"
                 class="overflow-hidden w-0"
                 ref="textContainer"
            >
                <div class="whitespace-nowrap"
                     v-if="showTextOnHover"
                     v-text="consentText"
                     ref="text"
                />
            </div>
        </transition>
    </button>
</template>

<script setup>
import { ref } from 'vue';
import gsap, { Power2 } from '@lahautesociete/gsap';

const props = defineProps({
    consentText: { type: String, required: true },
    ariaLabel: { type: String, default: null },
    showTextOnHover: { String, default: true },
});

const isHovered = ref(false);
const textContainer = ref(null);
const text = ref(null);
let tween;
function onEnter(element, done) {
    if (!props.showTextOnHover) {
        return;
    }

    if (tween) {
        tween.kill();
    }

    textContainer.value.style.width = 'auto';
    tween = gsap.to(textContainer.value, {
        width: text.value.clientWidth,
        duration: 0.5,
        ease: Power2.easeOut,
        onComplete: done,
    });
    textContainer.value.style.width = 0;
}
function onLeave(element, done) {
    if (!props.showTextOnHover) {
        return;
    }
    if (tween) {
        tween.kill();
    }

    tween = gsap.to(textContainer.value, {
        width: 0,
        duration: 0.5,
        ease: Power2.easeOut,
        onComplete: done,
    });
}
</script>

