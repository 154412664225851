<template>
    <div class="slider-timeline overflow-hidden lg:pt-20">
        <div class="slider-timeline__container grid-container container">
            <carousel :settings="settings" ref="carrousel" class="carousel-timeline relative" aria-label="" v-model="currentSlideId">
                <template #addons>
                    <Navigation />
                </template>
                <slot name="slides" />
            </carousel>
        </div>
    </div>
</template>

<script>
import {Carousel, Navigation} from 'vue3-carousel';
import { defineComponent } from 'vue';
import tailwindConfig from '@/../tailwind.config.js';

export default defineComponent({
    name: 'Breakpoints',
    components: {
        Carousel,
        Navigation
    },
    data: () => ({
        currentSlideId: 0,
        settings: {
            itemsToShow: 1,
            snapAlign: 'start',
            breakpoints: {
                [tailwindConfig.theme.screens.lg.replace(/px$/, '')]: { // dynamically fetch the lg breakpoint and remove the px unit as vue3-carousel expects a raw px value as an int
                    itemsToShow: 1.2,
                },
            },
        },
    }),
})
</script>
<style scoped lang="scss">
.slider-timeline {
    :deep(.slider-timeline__container) {
        .carousel__viewport {
            overflow: visible;
        }

        @screen md {
            .carousel__slide {
                align-items: start;
                //max-width: 347px;
            }
        }

        .carousel__slide {
            align-items: start;
            //max-width: 945px;
        }

        .carousel__prev {
            @apply invisible;
        }

        .carousel__next {
            @apply invisible;
        }

        @media screen and (min-width: 1440px) {
            .carousel__prev {
                @apply visible btn-arrow btn-arrow--dark btn-arrow--m btn-arrow--reverse;
                top: 0;
                right: 340px;
                left: unset;
                transform: translateY(-100%);

                &--in-active {
                    @apply opacity-50;
                }
            }

            .carousel__next {
                @apply visible btn-arrow btn-arrow--dark btn-arrow--m;
                top: 0;
                right: calc(300px - 12px);
                transform: translateY(-100%);

                &--in-active {
                    @apply opacity-50;
                }
            }

            .carousel__icon {
                display: none;
            }
        }
        @media screen and (min-width: 1023px) {
            .carousel__prev {
                @apply visible btn-arrow btn-arrow--dark btn-arrow--m btn-arrow--reverse;
                top: 0;
                right: 100px;
                left: unset;
                transform: translateY(-100%);


                &--in-active {
                    @apply opacity-50;
                }
            }

            .carousel__next {
                @apply visible btn-arrow btn-arrow--dark btn-arrow--m;
                top: 0;
                right: calc(60px - 12px);
                transform: translateY(-100%);


                &--in-active {
                    @apply opacity-50;
                }

            }

            .carousel__icon {
                display: none;
            }
        }
    }
}
</style>
