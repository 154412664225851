<template>
    <transition name="slide">
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: "Slide"
    }
</script>

<style scoped lang="scss">
    .slide-enter-active, .slide-leave-active {
        transition: opacity 300ms, transform 300ms;
    }
    .slide-enter-from, .slide-leave-to {
        opacity: 0;
        transform: translateY(-50px);
    }
</style>
