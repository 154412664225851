<template>
    <div class="fixed inset-0 z-40 flex bg-black text-white text-center md:text-left">
        <button :style="{ 'background-image': `url(${require('@/static/images/icons/cross-white.svg')}`}"
                class="absolute top-0 right-0 w-4 h-4 p-6 bg-no-repeat bg-center"
                :title="closeButtonAriaLabel"
                :aria-label="closeButtonAriaLabel"
                @click="toggleLanguageSelector"
        />
        <div class="grid-container container justify-center flex flex-col justify-center my-8">
            <div class="grid-row justify-center overflow-auto">
                <div class="grid-col w-full md:w-3/4 lead mb-8 md:mb-6"
                     v-text="title"
                />
                <nav class="grid-col w-full md:w-3/4" :aria-label="ariaLabel" >
                    <div v-for="({ name, sites }, continentKey) in continents" :key="continentKey">
                        <div class="continent mt-8 md:mt-10"
                             v-text="name"
                             ref="animated"
                        />
                        <ul class="flex flex-col md:flex-row">
                            <li v-for="(site, siteKey) in sites"
                                :key="siteKey"
                                class="h3 my-4 md:my-8 md:mr-20"
                                ref="animated"
                            >
                                <a :href="site.baseUrl" v-text="site.name" />
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import gsap from "@lahautesociete/gsap";


export default {
    props: {
        continents: Object,
        menuAriaLabel: String,
        closeButtonAriaLabel: String,
        title: String,
    },

    setup() {
        const animatedElements = ref(null);

        onMounted(() => {
            gsap.from(animatedElements.value, {
                x: 80,
                autoAlpha: 0,
                ease: 'power3.out',
                stagger: 0.1,
                force3D: true,
                delay: 0.2,
            });
        });

        return {
            animated: animatedElements,
            toggleLanguageSelector: inject('toggleLanguageSelector'),
        };
    },
}

</script>

<style scoped lang="scss">
.continent {
    @apply font-semibold uppercase;
    font-size: (13px / 16px * 1rem);
    line-height: 0.92;
    letter-spacing: 0.93px;
}
</style>
