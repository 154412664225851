<template>
    <div class="header-nav relative">
        <header
            :class="isTransparent ? 'absolute top-0 inset-x-0 z-30 text-white border-grey-60': 'relative z-30 text-black border-grey-90'"
            class="flex justify-between items-center py-6 px-6 lg:px-8 lg:pt-6 lg:pb-4 border-b"
        >
            <a :href="siteUrl">
                <img
                    :src="currentLogo.url"
                    :alt="currentLogo.alt"
                    width="223"
                    height="22"
                >
            </a>

            <div class="flex items-center">
                <nav class="hidden lg:flex lg:items-center" :aria-label="ariaLabels.mainMenu">
                    <a
                        v-for="(item, index, key) in mainMenu"
                        :key="key"
                        :href="item.url"
                        :target="item.target"
                        v-text="item.label"
                        class="navigation ml-10"
                    ></a>

                    <a
                        :href="featuredLink.url"
                        :target="featuredLink.target"
                        :class="isTransparent ? 'btn--light-primary': 'btn--dark-primary'"
                        v-text="featuredLink.label"
                        class="btn mx-8"
                    ></a>
                </nav>

                <button
                    :style="`background-image: url(${menuBtnImage})`"
                    :title="menuBtnAriaLabel"
                    :aria-label="menuBtnAriaLabel"
                    class="bg-center bg-contain bg-no-repeat w-5 h-5" @click="toggleMenu"
                ></button>
            </div>

            <slot />
        </header>

        <fade>
            <div v-if="isMenuOpened" class="hidden lg:block fixed inset-0 z-10 w-screen h-screen bg-black opacity-40" @click="toggleMenu"></div>
        </fade>

        <slide>
            <header-menu-desktop
                :secondary-menu="secondaryMenu"
                :aria-label="ariaLabels.secondaryMenu"
                v-if="isMenuOpened"
                :is-transparent="isTransparent"
            ></header-menu-desktop>
        </slide>

        <fade>
            <header-menu-mobile
                v-if="isMenuOpened"
                :featuredLink="featuredLink"
                :main-menu="mainMenu"
                :secondary-menu="secondaryMenu"
                :is-transparent="isTransparent"
                :aria-label="ariaLabels.mainMenu"
                :open-language-picker-aria-label="ariaLabels.openLanguagePicker"
                :current-site-name="currentSiteName"
            ></header-menu-mobile>
        </fade>
    </div>
</template>

<script>
import Fade from '@/app/transitions/Fade';
import Slide from '@/app/transitions/Slide';
import HeaderMenuDesktop from "@/app/components/HeaderMenuDesktop";
import HeaderMenuMobile from "@/app/components/HeaderMenuMobile";

export default {
    name: "HeaderNav",

    props: {
        logos: {
            type: Object,
            required: true,
        },
        siteUrl: {
            type: String,
            required: true,
        },
        featuredLink: {
            type: Object,
            required: true,
        },
        mainMenu: {
            type: Object,
            required: true,
        },
        secondaryMenu: {
            type: Object,
            required: true,
        },
        isTransparent: {
            type: Boolean,
            required: true,
        },
        ariaLabels: {
            type: Object,
            required: true,
        },
        currentSiteName: {
            type: String,
            required: true,
        },
    },

    components: {
        Fade,
        Slide,
        HeaderMenuDesktop,
        HeaderMenuMobile,
    },

    data() {
        return {
            isMenuOpened: false,
        }
    },

    computed: {
        currentLogo() {
            return this.isTransparent ? this.logos.light : this.logos.dark;
        },

        menuBtnImage() {
            if (this.isTransparent) {
                return this.isMenuOpened ? require('@/static/images/icons/cross-white.svg'): require('@/static/images/icons/burger-white.svg');
            }

            return this.isMenuOpened ? require('@/static/images/icons/cross-black.svg'): require('@/static/images/icons/burger-black.svg');
        },

        menuBtnAriaLabel() {
            return this.isMenuOpened ? this.ariaLabels.closeMenu: this.ariaLabels.openMenu;
        },
    },

    methods: {
        toggleMenu() {
            this.isMenuOpened = !this.isMenuOpened;
            document.body.style.overflow = this.isMenuOpened ? 'hidden': 'visible';
            window.scrollTo(0, 0);
        }
    }
}
</script>
