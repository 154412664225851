<template>
    <carousel :settings="settings" ref="carrousel" class="carousel-products" aria-label="">
        <slot name="slides" v-bind="{
        isFirstSlide,
        isLastSlide,
        slidesCount: carrousel ? carrousel.data.slidesCount.value : 0,
        currentSlide: carrousel ? carrousel.data.currentSlide.value : 0,
        prev: carrousel ? carrousel.prev : () => {},
        next: carrousel ? carrousel.next : () => {},
    }" />
    </carousel>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Carousel } from 'vue3-carousel';

const carrousel = ref(null);

const settings = {
    transition: 400,
};

const isFirstSlide = computed(() => {
    if (carrousel.value === null) {
        return false;
    }

    return carrousel.value.data.currentSlide.value === carrousel.value.data.minSlide.value;
});

const isLastSlide = computed(() => {
    if (carrousel.value === null) {
        return false;
    }

    return carrousel.value.data.currentSlide.value === carrousel.value.data.maxSlide.value;
});
</script>
<style scoped lang="scss">
:deep(.carousel__slide) {
    align-items: start;
}
</style>
