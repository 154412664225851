<template>
    <div class="featured-jobs my-16 lg:my-30 overflow-hidden">
        <div class="grid-container container">
            <div class="grid-row justify-center">
                <div class="grid-col w-full lg:w-2/3 text-center">
                    <slot></slot>
                </div>
                <div class="grid-col w-full mt-6 lg:mt-8 relative">
                    <carousel ref="carrousel" :settings="settings" :breakpoints="breakpoints" aria-label="">
                        <slide v-for="(slide, index, key) in  slides" :key="key">
                            <div
                                class="featured-jobs__slide w-full flex items-center justify-center text-center text-white bg-cover bg-center dark-overlay dark-overlay--40"
                                :style="`background-image: url('${slide.backgroundImage.url}')`"
                            >
                                <span class="h4 relative z-10">{{ slide.heading }}</span>
                            </div>
                        </slide>

                        <template #addons>
                            <navigation aria-hidden="true">
                                <template #prev>
                                    <div
                                        class="btn-arrow btn-arrow--light btn-arrow--m btn-arrow--reverse absolute top-1/2 left-0 transform -translate-y-1/2"
                                        :aria-label="ariaLabels.previous"
                                        :title="ariaLabels.previous"
                                    ></div>
                                </template>
                                <template #next>
                                    <div
                                        class="btn-arrow btn-arrow--light btn-arrow--m absolute top-1/2 right-0 transform -translate-y-1/2"
                                        :aria-label="ariaLabels.next"
                                        :title="ariaLabels.next"
                                    ></div>
                                </template>
                            </navigation>
                        </template>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';

export default {
    name: "FeaturedJobs",

    props: {
        slides: {
            type: Object,
            required: true,
        },
        ariaLabels: {
            type: Object,
            required: true,
        },
    },

    components: {
        Carousel,
        Slide,
        Navigation,
    },

    data() {
        return {
            settings: {
                itemsToShow: 1,
            },

            breakpoints: {
                768: {
                    itemsToShow: 2,
                },
                1024: {
                    itemsToShow: 3,
                    modelValue: 1,
                },
            }
        }
    },
}
</script>

<style scoped lang="scss">
.featured-jobs {
    .carousel :deep(.carousel__viewport) {
        overflow: visible;
    }

    .carousel :deep(.carousel__prev--in-active),
    .carousel :deep(.carousel__next--in-active) {
        display: none;
    }

    &__slide {
        height: 31.875rem;

        @screen lg {
            height: 35rem;
        }
    }
}
</style>
