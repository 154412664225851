<template>
    <div v-text="text"
         ref="textContainer"
         class="font-epilogue font-light text-[4.25rem] md:text-[8.75rem] leading-tight text-center max-w-full"
    />
</template>

<script setup>
import {nextTick, onMounted, onUnmounted, ref} from 'vue';
import gsap from "@lahautesociete/gsap";
import { SplitText } from "@lahautesociete/gsap/SplitText";

defineProps({
    text: { type: String, required: true },
})

const textContainer = ref(null);

let split;
onMounted(() => {
    nextTick(() => {
        split = new SplitText(textContainer.value, { type: 'lines' });
        gsap.set(split.lines, { y: '100%' });
        split.lines.forEach(line => {
            const wrappedLine = wrapElement(line);
            wrappedLine.classList.add('line-wrapper');
        });
    });
});

let intersectionObserver;
onMounted(() => {
    intersectionObserver = new IntersectionObserver(onIntersect, { threshold: [0.5] });
    intersectionObserver.observe(textContainer.value);
});

onUnmounted(() => {
    intersectionObserver.disconnect();
});

function onIntersect(entries, observer) {
    if (!entries[0].isIntersecting) {
        return;
    }

    observer.disconnect();

    gsap.to(split.lines, { duration: 0.75, y: 0, ease: "power3", stagger: 0.15 });
}

function wrapElement(element) {
    const wrapper = document.createElement('div');
    const container = element.parentNode;
    container.removeChild(element);
    container.append(wrapper);
    wrapper.append(element);
    return wrapper;
}
</script>

<style scoped>
>>> .line-wrapper {
    @apply overflow-hidden;
}
</style>
