<template>
<header class="h-screen relative overflow-hidden text-white" ref="root">
    <div class="absolute inset-0 dark-overlay dark-overlay--40">
        <slot name="background-image" />
        <div v-show="isConsentGiven">
            <slot name="video" />
        </div>
    </div>
    <div v-if="hasVideo && !isConsentGiven"
         class="absolute bottom-0 left-0 right-0 z-10 text-center"
    >
        <button class="caption p-4"
                v-text="cookieConsent"
                @click="consent"
        ></button>
    </div>

    <div class="hero__content relative grid-container container h-full">
        <div class="grid-row items-center h-full">
            <div class="grid-col w-1/12 hidden lg:block"></div>
            <section class="grid-col w-full lg:w-2/3">
                <slot name="text" />
            </section>
        </div>
    </div>

</header>
</template>

<script setup>
import {ref, onMounted, onUnmounted, computed, useSlots} from 'vue';

const props = defineProps({
    videoProvider: String,
    cookieConsent: { type: String, default: 'Accept cookies and play the vidéo' },
});

const slots = useSlots();
const hasVideo = computed(() => {
    return slots.video && slots.video().length;
});

const root = ref(null);
const isConsentGiven = ref(false);
function consent() {
    const tarteaucitronEnableButton = root.value.querySelector('.tarteaucitronAllow');
    if (tarteaucitronEnableButton) {
        window.tarteaucitron.userInterface.respond(tarteaucitronEnableButton, true);
    }
}

/*
    Ensure tarteaucitron is ready and check if the tarteaucitron service
    matching the video provider has been allowed.
 */
const tarteaucitronServiceAddedEvent = `${props.videoProvider}_added`;
const tarteaucitronServiceAllowedEvent = `${props.videoProvider}_allowed`;
onMounted(() => {
    if (window.tarteaucitron.added[props.videoProvider]) {
        onTarteaucitronServiceLoaded();
        return;
    }

    document.addEventListener(tarteaucitronServiceAddedEvent, onTarteaucitronServiceLoaded);
});
onUnmounted(() => {
    document.removeEventListener(tarteaucitronServiceAddedEvent, onTarteaucitronServiceLoaded);
    document.removeEventListener(tarteaucitronServiceAddedEvent, onConsentGiven);
});
function onTarteaucitronServiceLoaded() {
    isConsentGiven.value = !!window.tarteaucitron.state[props.videoProvider];

    // If the service is not allowed, listen for the tarteaucitron event fired if the user end up allowing it
    if (!isConsentGiven.value) {
        document.addEventListener(tarteaucitronServiceAllowedEvent, onConsentGiven);
    }
}
function onConsentGiven() {
    isConsentGiven.value = true;
}
</script>
