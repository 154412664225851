import '@fontsource/epilogue/200.css';
import '@fontsource/epilogue/400.css';
import '@fontsource/epilogue/500.css';
import '@fontsource/epilogue/600.css';
import '@/assets/styles/main.scss';
import 'vue3-carousel/dist/carousel.css';

import { createApp, onMounted, provide, ref, toRef } from 'vue';
import { Slide } from 'vue3-carousel';
import popinState from '@/app/state/popin';
import CsrfInput from '@/app/components/CsrfInput.vue';
import HeaderNav from '@/app/components/HeaderNav.vue';
import FlashMessage from '@/app/components/FlashMessage';
import SliderTestimonials from '@/app/components/SliderTestimonials.vue';
import FeaturedJobs from '@/app/components/FeaturedJobs.vue';
import FeaturedProfilesHomepage from '@/app/components/FeaturedProfilesHomepage.vue';
import LanguagePicker from '@/app/components/LanguagePicker.vue';
import Fade from '@/app/transitions/Fade.vue';
import ThreeFeatures from '@/app/components/ThreeFeatures.vue';
import TextAppearBottom from '@/app/components/TextAppearBottom.vue';
import SliderAccessories from '@/app/components/SliderAccessories.vue';
import SliderCareers from "@/app/components/SliderCareers.vue";
import SliderProducts from '@/app/components/SliderProducts.vue';
import SliderAwards from '@/app/components/SliderAwards.vue';
import SliderTimeline from "@/app/components/SliderTimeline.vue";
import ScrollingText from '@/app/components/ScrollingText.vue';
import FullWidthVideo from '@/app/components/FullWidthVideo.vue';
import Hero from '@/app/components/Hero.vue';
import BackgroundColorTransition from '@/app/components/BackgroundColorTransition.vue';
import Popin from '@/app/components/Popin.vue';

createApp({
    name: 'App',
    components: {
        CsrfInput,
        HeaderNav,
        FlashMessage,
        SliderTestimonials,
        FeaturedJobs,
        FeaturedProfilesHomepage,
        LanguagePicker,
        Fade,
        ThreeFeatures,
        TextAppearBottom,
        SliderAccessories,
        Slide,
        SliderCareers,
        SliderProducts,
        SliderAwards,
        SliderTimeline,
        ScrollingText,
        FullWidthVideo,
        Hero,
        BackgroundColorTransition,
        Popin,
    },

    setup() {
        let isLanguagePickerOpen = ref(false);
        function toggleLanguageSelector() {
            isLanguagePickerOpen.value = !isLanguagePickerOpen.value;
            document.body.style.overflow = isLanguagePickerOpen.value ? 'hidden': 'visible';
        }
        provide('toggleLanguageSelector', toggleLanguageSelector);

        onMounted(() => {
            if(document.location.hash) {
                setTimeout(()=> {
                    const element = document.querySelector(document.location.hash);
                    element.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 1000)
            }
        });

        return { isPopinOpened: toRef(popinState.state, 'isOpened'), toggleLanguageSelector, isLanguagePickerOpen };
    },
}).mount('#app');
