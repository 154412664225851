<template>
    <div :class="isTransparent ? 'bg-black text-white': 'bg-white text-black'" class="header-menu-desktop hidden lg:block absolute top-0 inset-x-0 pt-24 z-20">
        <nav class="flex items-center justify-center py-24" :aria-label="ariaLabel">
            <a
                ref="links"
                v-for="(item, index, key) in secondaryMenu"
                :key="key"
                :href="item.url"
                :target="item.target"
                v-text="item.label"
                class="lead mx-10"
            ></a>
        </nav>
    </div>
</template>

<script>
import gsap from "@lahautesociete/gsap";

export default {
    name: "HeaderMenuDesktop",

    props: {
        secondaryMenu: {
            type: Object,
            required: true,
        },
        ariaLabel: {
            type: String,
            required: true,
        },
        isTransparent: {
            type: Boolean,
            required: true,
        },
    },

    mounted() {
        gsap.from(this.$refs.links, {
            x: 80,
            autoAlpha: 0,
            ease: 'power3.out',
            stagger: 0.1,
            force3D: true,
            delay: 0.2,
        });
    }
}
</script>

<style scoped>

</style>
