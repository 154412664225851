<script setup>
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import popinState from '@/app/state/popin.js';
import {onMounted, ref, nextTick, onUnmounted} from "vue";

const root = ref(null);
const modal = ref(null);

function close() {
    enableBodyScroll(modal.value);
    popinState.close();
}

onMounted(() => {
    disableBodyScroll(modal.value, { reserveScrollBarGap: true });

    root.value.tabIndex = 0;

    nextTick(function() {
        root.value.focus();
    });

});

onUnmounted(() => {
    clearAllBodyScrollLocks();
});
</script>

<template>
    <div class="modal fixed inset-0 flex justify-center items-center z-50 p-6 lg:p-10"
         role="dialog"
         aria-labelledby="modalTitle"
         aria-describedby="modalDescription"
         ref="root"
    >
        <div class="modal__backdrop absolute inset-0 bg-black opacity-50" />

        <aside class="modal__content relative bg-white w-full max-w-3xl p-4 lg:p-10" ref="modal">
            <slot v-bind:closePopin="close" />
        </aside>
    </div>
</template>

<style scoped lang="scss">
.modal {
    &__content {
        transition: all 250ms ease-out;
    }
}
</style>

<style lang="scss">
.modal-fade-enter,
.modal-fade-leave-active {
    opacity: 0;

    .modal__content {
        transform: translate3d(0, 2.5rem, 0);
    }
}

.modal-fade-enter-active {
    transition: opacity 250ms ease-out;
}

.modal-fade-leave-active {
    transition: opacity 250ms ease-in;
}
</style>
