<template>
    <intersector @enter="enterHandler">
        <div class="slider-careers relative">
            <carousel ref="carousel" :settings="settings" class="carousel-careers" aria-label="" v-model="currentSlideId">
                <slot name="slides" />
            </carousel>
            <div class="bg-grey-70">
                <div ref="timeline" class="slider-careers__timeline w-full h-0.5 bg-white"></div>
            </div>
            <div class="lg:flex ml-auto" aria-hidden="true">
                <button @click="prev()"
                        class="absolute btn-prev btn-arrow btn-arrow--light btn-arrow--m btn-arrow--reverse mr-3"
                        aria-label="{{ 'a11y.aria.labels.slider.previous'|t('dico') }}"
                ></button>
                <button @click="next()"
                        class="absolute btn-next btn-arrow btn-arrow--light btn-arrow--m"
                        aria-label="{{ 'a11y.aria.labels.slider.next'|t('dico') }}"
                ></button>
            </div>
        </div>
    </intersector>
</template>

<script>
import {Carousel} from 'vue3-carousel';
import {gsap} from '@lahautesociete/gsap';
import Intersector from "@/app/components/Intersector.vue";

export default {
    name: 'SliderCareers',

    props: {
        slidesCount: {
            type: Number,
            required: true,
        },
    },

    components: {
        Carousel,
        Intersector,
    },

    data: () => ({
        currentSlideId: 0,

        settings: {
            itemsToShow: 1,
        },

        duration: 6,

        intervalId: null,
    }),

    unmounted() {
        clearInterval(this.intervalId);
    },

    methods: {
        resetTimeline() {
            gsap.fromTo(this.$refs.timeline, {
                width: 0,
            }, {
                width: '100%',
                duration: this.duration,
                ease: 'linear',
            });
        },

        enterHandler() {
            this.resetTimeline();

            this.intervalId = setInterval(() => {
                this.resetTimeline();
                this.next(false);
            }, this.duration*1000);
        },

        prev() {
            clearInterval(this.intervalId);

            if (this.currentSlideId === 0) {
                this.currentSlideId = this.slidesCount-1;
                return;
            }

            this.currentSlideId--;
        },

        next(stopAutoplay = true) {
            if(stopAutoplay) {
                clearInterval(this.intervalId);
            }

            if(this.currentSlideId === this.slidesCount-1) {
                this.currentSlideId = 0;
                return;
            }

            this.currentSlideId++;
        }
    },
}
</script>
<style scoped lang="scss">
.slider-careers {
    .btn-prev {
        bottom: 0;
        left: 24px;
        transform: translatey(-100%);
    }
    .btn-next {
        bottom:0;
        left: calc(24px + 12px);
        transform: translateX(100%) translateY(-100%);
    }
    @screen md {
        .btn-prev {
            bottom: 0;
            right: calc(10% + 12px);
            transform: translatey(-100%);
            left: unset;
        }
        .btn-next {
            bottom:0;
            right: 10%;
            transform: translateX(100%) translateY(-100%);
            left: unset;
        }
    }
}
</style>
