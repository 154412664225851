<template>
    <div class="slider-testimonials overflow-hidden my-16 lg:my-30">
        <div class="grid-container container">
            <div class="grid-row">
                <div class="grid-col w-full lg:w-1/2 mb-4 lg:mb-8">
                    <h2 class="h3">{{ heading }}</h2>
                </div>
                <div class="grid-col hidden lg:flex justify-end w-1/2" aria-hidden="true">
                    <button
                        class="btn-arrow btn-arrow--dark dark:btn-arrow--light btn-arrow--m btn-arrow--reverse"
                        :disabled="currentSlideId === 0"
                        :aria-label="ariaLabels.previous"
                        :title="ariaLabels.previous"
                        @click="$refs.carrousel.prev()"
                    ></button>
                    <button
                        class="btn-arrow btn-arrow--dark dark:btn-arrow--light btn-arrow--m ml-4"
                        :disabled="currentSlideId >= slides.length-1"
                        :aria-label="ariaLabels.next"
                        :title="ariaLabels.next"
                        @click="$refs.carrousel.next()"
                    ></button>
                </div>
                <div class="grid-col w-full">
                    <carousel ref="carrousel" :settings="settings" :breakpoints="breakpoints" v-model="currentSlideId" aria-label="">
                        <slide v-for="(slide, index, key) in  slides" :key="key">
                            <div class="px-1 w-full h-full">
                                <div class="flex flex-col justify-between w-full h-full bg-grey-95 dark:bg-grey-10 p-8 lg:px-12 lg:py-10 text-left">
                                    <div class="wysiwyg-content lead" v-html="slide.quotation"></div>
                                    <div class="flex items-start mt-6 lg:mt-10" v-if="slide.author || slide.position || slide.photo">
                                        <img
                                            v-if="slide.photo"
                                            :src="slide.photo.url"
                                            :alt="slide.photo.alt"
                                            :width="slide.photo.width"
                                            :height="slide.photo.height"
                                        >
                                        <div class="flex flex-col ml-4">
                                            <span class="body-2-medium">{{ slide.author }}</span>
                                            <span class="body-2">{{ slide.position }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
export default {
    name: "SliderTestimonials",

    props: {
        heading: {
            type: String,
            required: true,
        },
        slides: {
            type: Object,
            required: true,
        },
        ariaLabels: {
            type: Object,
            required: true,
        },
    },

    components: {
        Carousel,
        Slide,
    },

    data() {
        return {
            currentSlideId: 0,

            settings: {
                itemsToShow: 1,
            },

            breakpoints: {
                1024: {
                    itemsToShow: 2,
                },
            }
        }
    },
}
</script>

<style scoped lang="scss">
.slider-testimonials {
    .carousel :deep(.carousel__viewport) {
        overflow: visible;
    }
}
</style>
