<template>
    <div :class="isTransparent ? 'bg-black text-white': 'bg-white text-black'" class="header-menu-mobile lg:hidden pt-18 fixed inset-0 z-20 w-screen h-screen">
        <div class="w-full h-full overflow-auto">
            <nav class="h-full flex flex-col items-center text-center pt-8 pb-6" :aria-label="ariaLabel">
                <a
                    :href="featuredLink.url"
                    :target="featuredLink.target"
                    v-text="featuredLink.label"
                    :class="isTransparent ? 'btn--light-primary': 'btn--dark-primary'"
                    class="btn mb-8"
                ></a>
                <a
                    ref="links"
                    v-for="(item, index, key) in mainMenu"
                    :key="key"
                    :href="item.url"
                    :target="item.target"
                    v-text="item.label"
                    class="navigation mb-8"
                ></a>
                <a
                    ref="links"
                    v-for="(item, index, key) in secondaryMenu"
                    :key="key"
                    :href="item.url"
                    :target="item.target"
                    v-text="item.label"
                    class="navigation mb-8"
                ></a>
                <a href="#lang"
                   @click.prevent.stop="$root.toggleLanguageSelector()"
                   class="caption link-with-arrow link-with-arrow--chevron-dark link-with-arrow--s mt-auto underline"
                   v-text="currentSiteName"
                   :aria-label="openLanguagePickerAriaLabel"
                   :title="openLanguagePickerAriaLabel"
                />
            </nav>
        </div>
    </div>
</template>

<script>
import gsap from "@lahautesociete/gsap";

export default {
    name: "HeaderMenuMobile",

    props: {
        featuredLink: {
            type: Object,
            required: true,
        },
        mainMenu: {
            type: Object,
            required: true,
        },
        secondaryMenu: {
            type: Object,
            required: true,
        },
        isTransparent: {
            type: Boolean,
            required: true,
        },
        ariaLabel: {
            type: String,
            required: true,
        },
        currentSiteName: {
            type: String,
            required: true,
        },
        openLanguagePickerAriaLabel: {
            type: String,
            required: true,
        }
    },

    mounted() {
        gsap.from(this.$refs.links, {
            x: 80,
            autoAlpha: 0,
            ease: 'power3.out',
            stagger: 0.1,
            force3D: true,
            delay: 0.2,
        });
    }
}
</script>

<style scoped>

</style>
