<template>
    <intersector :threshold="[0.3]" @enter="play">
        <div class="featured-profiles-homepage overflow-hidden my-16 lg:my-30">
            <div class="grid-container container relative">
                <h3 class="featured-profiles-homepage__heading uppercase absolute w-1/2 lg:w-full z-10 text-white lg:text-center font-semibold">{{ heading }}</h3>

                <carousel ref="carrousel" :settings="settings" :breakpoints="breakpoints" aria-label="" v-model="currentSlideId">
                    <slide v-for="(slide, index, key) in  slides" :key="key">
                        <div
                            class="featured-profiles-homepage__slide w-full flex flex-col items-center justify-center text-center text-white bg-cover bg-center dark-overlay dark-overlay--40"
                            :style="`background-image: url('${slide.backgroundImage.url}')`"
                        >
                            <h4 class="slide-heading relative z-10">{{ slide.heading }}</h4>
                            <p class="relative z-10 body-1 lg:w-2/3" v-html="slide.text"></p>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </intersector>
</template>

<script>
import Intersector from "@/app/components/Intersector";
import { Carousel, Slide } from 'vue3-carousel';

export default {
    name: "FeaturedProfilesHomepage",

    props: {
        heading: {
            type: String,
            required: true,
        },
        slides: {
            type: Object,
            required: true,
        },
    },

    components: {
        Intersector,
        Carousel,
        Slide,
    },

    data() {
        return {
            intervalId: null,
            currentSlideId: 0,
            settings: {
                itemsToShow: 1,
                autoplay: 3000,
                transition: 400,
                wrapAround: true
            },

            breakpoints: {
                1024: {
                    itemsToShow: 2,
                },
            }
        }
    },

    unmounted() {
        clearInterval(this.intervalId);
    },

    methods: {
        play() {
            this.intervalId = setInterval(() => {
                if(this.currentSlideId === this.slides.length - 1) {
                    this.currentSlideId = 0;
                    return;
                }

                this.currentSlideId++
            }, 100000);
        },
    }
}
</script>

<style scoped lang="scss">
    .featured-profiles-homepage {
        .carousel :deep(.carousel__viewport) {
            overflow: visible;
        }

        &__slide {
            height: 26.25rem;

            @screen lg {
                height: 43.75rem;
            }
        }

        &__heading {
            top: 18%;
            left: 31%;
            font-size: 1rem;
            letter-spacing: -0.57px;

            @screen lg {
                top: 35%;
                left: 0;
            }
        }

        .slide-heading {
            font-size: 1.75rem;
            line-height: 3rem;
            letter-spacing: -1px;
        }
    }
</style>
