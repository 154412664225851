<template>
    <div class="relative">
        <div class="md:absolute right-0 top-[-5.625rem] container grid-container">
            <div class="grid-row">
                <div class="grid-col w-full">
                    <slot name="navigation"
                          :carrousel="carrousel"
                          :is-first-slide="isFirstSlide"
                          :is-last-slide="isLastSlide"
                    ></slot>
                </div>
            </div>
        </div>
        <div class="overflow-hidden">
            <div class="container grid-container">
                <div class="grid-row">
                    <div class="grid-col w-full">
                        <carousel :settings="settings" ref="carrousel" aria-label="">
                            <slot name="default" />
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Carousel } from 'vue3-carousel';

const carrousel = ref(null);

const settings = {
    itemsToShow: 1.1,
    transition: 400,
    snapAlign: 'start',
    breakpoints: {
        1024: {
            itemsToShow: 3.2,
        },
    },
};

const isFirstSlide = computed(() => {
    if (carrousel.value === null) {
        return false;
    }

    return carrousel.value.data.currentSlide.value === carrousel.value.data.minSlide.value;
});

const isLastSlide = computed(() => {
    if (carrousel.value === null) {
        return false;
    }

    return carrousel.value.data.currentSlide.value === carrousel.value.data.maxSlide.value;
});
</script>

<style scoped lang="scss">
.carousel :deep(.carousel__viewport) {

    .carousel__slide {
        align-items: unset;
    }
    overflow: visible;
}
</style>
