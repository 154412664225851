<template>
    <div ref="root">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Intersector",

    props: {
        threshold: {
            type: Array,
            required: true,
        },
        triggerOnce: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['enter', 'leave'],

    data() {
        return {
            hasBeenVisible: false,
        }
    },

    mounted() {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.triggerOnce ? this.enterHandler(): this.$emit('enter');
                    }
                    else {
                        this.$emit('leave');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: this.threshold,
            }
        );
        observer.observe(this.$refs.root);
    },

    methods: {
        enterHandler() {
            if (this.hasBeenVisible) {
                return;
            }

            this.hasBeenVisible = true;
            this.$emit('enter');
        },
    },
}
</script>
